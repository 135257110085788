export default {
	name: 'List',
	props: {
		'targetField': {
			type: String,
			default: 'id'
		},
		'options':{
			type: Array,
			default: () => []
		},
		'optionsTitle':{
			type: String,
			default: ''
		},
		'optionsSearched':{
			type: Array,
			default: () => []
		},
		'optionsSearchedTitle':{
			type: String,
			default: ''
		},
		'optionsPopular':{
			type: Array,
			default: () => []
		},
		'optionsPopularTitle':{
			type: String,
			default: ''
		},
		'isOptionsHidden': {
			type: Boolean,
			default: true
		},
		'hoveredIndex':{
			type: Number,
			default: -1
		},
		'selected':{
			type: [String, Number],
			default: []
		},
		'emptyText':{
			type: String,
			default: 'Співпадіння відсутні'
		},
	},
	components: {
		'autocomplete-item': require('./../Item/index.vue').default
	},
	methods: {
		select(option) {
			this.$emit('set', option)
		},
		optionSearchedSafeClasses(index, targetField) {
			return {
				active: index === this.hoveredIndex || this.selected == targetField,
				check: this.selected == targetField
			}
		},
		optionPopularSafeClasses(index, targetField) {
			return {
				active: ((index + this.optionsPopular.length) === this.hoveredIndex) || this.selected == targetField,
				check: this.selected == targetField
			}
		},
		optionSafeClasses(index, targetField) {
			return {
				active: ((index +  this.optionsPopular.length) === this.hoveredIndex) || this.selected == targetField,
				check: (this.selected == targetField)
			}
		},
	},
	computed: {
		optionsSearchedSafe() {
			return this.optionsSearched.length ? this.optionsSearched.slice(0, 5) : [];
		}
	},
	updated() {
		if (!this.isOptionsHidden) {
			let item = this.$el.querySelector('.active');
			if (item) {
				this.$el.scrollTop = item.offsetTop - 50;
			}
		}
	}
};
