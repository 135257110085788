export default {
    messages: {
        uk: {
            'Шукати за ID, VIN чи держ номером': 'Шукати за ID, VIN чи держ номером',
            'Шукати за параметрами': 'Шукати за параметрами',
            'Редагувати параметри':  'Редагувати параметри'
        },
        ru:{
            'Шукати за ID, VIN чи держ номером': 'Искать по ID, VIN или гос номеру',
            'Шукати за параметрами': 'Искать по параметрам',
            'Редагувати параметри':  'Редактировать параметри'
        }
    }
}
