export default {
    messages: {
        uk: {
            'Дізнайтесь ринкову вартість автомобіля онлайн': 'Дізнайтесь ринкову вартість автомобіля онлайн'
        },
        ru: {
            'Дізнайтесь ринкову вартість автомобіля онлайн': 'Узнайте рыночную стоимость автомобиля онлайн'
        }
    }
}
