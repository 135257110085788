import loopify from "../../../../../app/helpers/loopify";

export default {
    metaInfo() {
        return {
            bodyAttrs: {
                'class': this.mobilePopupActive ? 'autocomplete-popup' : ''
            }
        };
    },
    props: {
        id: {
            type: [String, Number],
            required: true
        },
        labelText: {
            type: String,
            default: ''
        },
        targetField: {
            type: String,
            default: 'id'
        },
        selected: {
            type: [String, Number],
            default: 0
        },
        selectedName: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        optionsTitle: {
            type: String,
            default: ''
        },
        optionsPopular: {
            type: Array,
            default: () => []
        },
        optionsPopularTitle: {
            type: String,
            default: ''
        },
        optionsSearched: {
            type: Array,
            default: () => []
        },
        optionsSearchedTitle: {
            type: String,
            default: ''
        },
        suggests: {
            type: Array,
            default: () => []
        },
        emptyText: {
            type: String,
            default: ''
        },
        optionsListIsHide: {
            type: Boolean,
            default: true
        },
        active: {
            type: Boolean,
            default: true
        },
        ga: {
            type: [Array],
            default: () => []
        }
    },
    data: function () {
        return {
            isOptionsHidden: true,
            suggesting: false,
            mounted: false,
            hoveredIndex: -1,
            hoveredSuggestIndex: -1,
            searchActive: false,
            searchText: ''
        };
    },
    computed: {
        autoCompleteId() {
            return 'autocomplete-' + this.id
        },
        mobilePopupActive() {
            return !this.isOptionsHidden && this.isMobile;
        },
        allOptions() {
            return [...this.optionsSearched, ...this.optionsPopular, ...this.options];
        },
        allOptionsLength() {
            return this.allOptions.length || 0;
        },
        inputText() {
            return this.selectedName || this.labelText
        }
    },
    watch: {
        selected() {
            if (this.selected === 0) {
                this.suggesting = false;
                this.searchText = '';
            } else {
                this.searchText = this.selectedName;
            }
        }
    },
    methods: {
        open() {
            if (this.ga.length) {
                this.gaEvent(...ga);
            }
            this.showOptionsList();
        },
        hideOptionsList() {
            this.suggesting = false;
            this.isOptionsHidden = true;
            this.searchText = this.selectedName;
            this.focusOut()
        },
        showOptionsList() {
            if(this.allOptions.length) {
                this.isOptionsHidden = false;
            }
        },
        select(value) {
            this.$emit('set', value);
            this.suggesting = false;
            this.hideOptionsList();
        },
        resetInput() {
            this.suggesting = false;
            this.$emit('set', null);
            this.hoveredIndex = -1;
            this.hoveredSuggestIndex = -1;
            this.searchText = '';
            this.showOptionsList();
        },
        input(event) {
            const {target: {value} = {}} = event;
            this.$emit('searchChange', value);
            if (value) {
                this.searchText = value;
                this.suggesting = true;
                this.showOptionsList();
            } else {
                this.resetInput();
            }
        },
        keydown(event){
            if(this.isMobile) return;
            switch (event.keyCode) {
                case 9: { // tab
                    this.hideOptionsList();
                    return;
                }
                case 27: { // esc
                    this.hideOptionsList();
                    return;
                }
                case 38: { // up
                    this.showOptionsList();
                    let index
                    if (this.suggesting) {
                        index = loopify(--this.hoveredSuggestIndex, this.optionsSearched.length);
                        this.hoveredSuggestIndex = Number.isFinite(index) ? index : -1;
                    } else {
                        index = loopify(--this.hoveredIndex, this.allOptionsLength);
                        this.hoveredIndex = Number.isFinite(index) ? index : -1;
                    }
                    event.preventDefault();
                    return;
                }
                case 40: { // down
                    this.showOptionsList();
                    let index
                    if (this.suggesting) {
                        index = loopify(++this.hoveredSuggestIndex, this.optionsSearched.length);
                        this.hoveredSuggestIndex = Number.isFinite(index) ? index : -1;
                    } else {
                        index = loopify(++this.hoveredIndex, this.allOptionsLength);
                        this.hoveredIndex = Number.isFinite(index) ? index : -1;
                    }
                    event.preventDefault();
                    return;
                }
                // case 13: { // enter
                //     if (this.suggesting) {
                //         if (this.hoveredSuggestIndex !== -1) {
                //             const index = this.hoveredSuggestIndex;
                //             const {value} = this.optionsSearched[index] || {};
                //             this.select(Number.parseInt(value));
                //         }
                //     } else {
                //         if (this.hoveredIndex !== -1) {
                //             let {[this.hoveredIndex]: {value} = {}} = this.allOptions;
                //             this.select(value);
                //         }
                //     }
                //     event.preventDefault();
                //     return;
                // }
            }
        },
        focusIn() {
            this.$emit('focusin');
        },
        focusOut() {
            this.$emit('focusout');
        }
    },
}
