import translate from "./i18n";
import {mapActions} from "vuex";

export default {
    name: 'Chips',
    props: {
        chips: {
            type: Array,
            required: true
        },
        subLinkTitle: {
            type: String,
            default: ''
        },
        subLinkUrl: {
            type: String,
            default: '#'
        },
        removeAvailable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        ...mapActions('landing/averagePrice/search', {
            updateLoggerData: 'updateAVGPriceLoggerData',
        }),
        loggerAVGPriceChips() {
            try {
                this.updateLoggerData({ click_action: 1, action_block: 3, current_url: this.subLinkUrl });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceChips===', e.toString());
            }
        }
    },
    i18n: {
        ...translate
    },
}
