import autocomplete from './mixins/autocomplete'
import translate from "./i18n";

export default  {
    name: 'CommonAutoComplete',
    mixins: [autocomplete],
    components: {
        'List': require('./components/List/index.vue').default,
        'ListBySearch': () => import('./components/ListBySearch/index.vue'),
    },
    i18n: {
        ...translate
    }
}
