import translate from "./i18n";

export default {
    name: 'FieldPromptWrapper',
    props: {
        fields: {
            type: Object,
            required: true,
        },
        fieldData: {
            type: Object,
            default: []
        }
    },
    data() {
        return {
            dependenciesIsNotField: []
        }
    },
    computed: {
        dependenceOn() {
            return this?.fieldData?.dependencies?.on || [];
        },
        dependencyText() {
            if(!this.dependenciesIsNotField.length) return '';
            return this.dependenciesIsNotField.map(item => this.$t(item)).join(', ')
        },
        showValidationMessage() {
            return this.fieldData.validation.showMessage || false
        },
        validators() {
            return this.fieldData.validation.validators;
        },
        validationMessage() {
            const validators = Object.entries(this.validators);
            if(!validators.length) return '';

            const notValidValidator = validators.find(([key, validatorData]) => !validatorData.valid);
            return notValidValidator.length ? this.getValidationText({ validator: notValidValidator[0] })  : null;
        },
        hasTip() {
            return this.dependencyText || (this.showValidationMessage && this.validationMessage)
        }
    },
    methods: {
        checkDependencyIsFilled() {
            if (!this.dependenceOn.length) return;
            this.dependenciesIsNotField = [];
            this.dependenceOn.forEach((name) => {
                if(!this.fields[name]?.value) {
                    this.dependenciesIsNotField.push(this.fields?.[name]?.fieldName)
                }
            })
        },
        checkDependency() {
            this.checkDependencyIsFilled();
        },
        hidePrompt() {
            this.dependenciesIsNotField = [];
        },
        getValidationText({ validator }) {
            return this.$t(validator, { field: this.$t(this.fieldData.fieldName) })
        }
    },
    i18n: {
        ...translate
    }
}
