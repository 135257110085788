import field from "../mixins/field";
import translate from "./i18n";

export default {
    name: 'FormAverageSelect',
    mixins: [field],
    components: {
        'select-field-wrapper': require('./../../../../../Common/SelectFieldWrapper/index.vue').default,
        'select-item': require('./components/Item/index.vue').default,
        'field-prompt-wrapper': require('../FieldPromptWrapper/index.vue').default

    },
    computed: {
        selectId() {
            return this.fieldData?.id
        },
        selectBackgroundId() {
            return 'selectBackground-' + this.selectId
        },
        selectedItemNames() {
            return this.selectedValue?.name || ''
        },
        selectPlaceholderText() {
            return this.fieldData?.fieldAttrs?.placeholder || 'Всі'
        },
        options() {
            return [...this.fieldData?.defaultOptions, ...this.fieldData?.options];
        },

        selectedValue() {
            return this.fieldData?.options.find(el => Number(el.value) === Number(this.fieldValue)) || null
        },
        fieldType() {
            return this.fieldData?.fieldAttrs.type || 'radio'
        }
    },
    methods: {
        handlerChange({ data }) {
            const value = data.value.toString();
            this.fieldChange({
                name: this.fieldData.fieldName,
                value
            });
            if(this.fieldData?.fieldAttrs?.type === 'radio') {
                this.$refs.select.close()
            }
        },
        isChecked(value){
            if(!this.selectedValue) return false;
            return Number(value) === Number(this.fieldValue);
        }
    },
    i18n: {
        ...translate
    }
}


