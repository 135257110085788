import field from "../mixins/field";

export default {
    // Данинй компонент виконує логіку тільки для використання як компонент сторінки Середня ціна тк як:
    //    - actions Налаштовані тільки для співпраці в формі Оцінки середньої ціни
    //    - всі данні підтягуються з даної форми як і залежності
    // Бо він є обгорткою для використання CommonAutocomplete
    name: 'FormAverageAutocomplete',
    components: {
        'autocomplete': require('./../../../../../Common/Autocomplete/index.vue').default,
        'field-prompt-wrapper': require('../FieldPromptWrapper/index.vue').default
    },
    mixins: [field],
    computed: {
        autoCompleteId() {
            return  this.fieldData.fieldName + this.fieldData.id
        },
        labelText() {
            return this.fieldData.label
        },
        options() {
            return this.fieldData.options
        },
        optionsTitle(){
            return this.fieldData.common.title
        },
        optionsSearched() {
            return this.fieldData.search.options
        },
        optionsSearchedTitle() {
            return this.fieldData.search.title
        },
        optionsPopular() {
            return this.fieldData.popular.options
        },
        optionsPopularTitle() {
            return this.fieldData.popular.title
        },
        emptyText() {
            return this.fieldData.search.emptyText
        },
        selected() {
            return this.fieldData.value
        },
        selectedName() {
            if(!this.selected) return '';
            const selectedOption = this.options.find(el => Number(el.value) === Number(this.selected));
            return selectedOption?.name || '';
        }
    },
    methods: {
        handlerChange(value) {
            const selectedValue = value?.value;
            this.fieldChange({
                name: this.fieldData.fieldName,
                value: selectedValue || null
            })
        },
        changeSearch(value) {
            this.fieldSearch(value)
        }
    }
}
