import translate from "./i18n";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'FormTabs',
    props: {
        currentTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        }
    },
    computed:{
        ...mapGetters('landing/averagePrice', {
            loggerDataDefault: 'search/getLoggerDataDefault',
            chipsData: 'getChipsData',
        }),
        buttonText() {
            if(this.chipsData?.chips?.length && this.currentTab !== 'byParams') {
                return this.$t('Редагувати параметри')
            }
            return this.currentTab === 'byParams' ? this.$t('Шукати за ID, VIN чи держ номером') : this.$t('Шукати за параметрами')
        }
    },
    methods:{
        ...mapActions('landing/averagePrice/search', {
            updateLoggerData: 'updateAVGPriceLoggerData',
        }),
        loggerAVGPriceTab(tabType) {
            try {
                this.updateLoggerData({ click_action: this.loggerDataDefault.mapTabs[tabType], action_block: 2 });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceTab===', e.toString());
            }
        },
        changeTab() {
            const nextTab = this.currentTab === 'byParams' ? 'byOmniId' : 'byParams'
            this.loggerAVGPriceTab(nextTab)
            this.$emit('changeTab', nextTab)
        }
    },
    i18n: {
        ...translate
    }
}
