export default {
    messages: {
        uk: {
            'Пошук за параметрами авто': 'Пошук за параметрами авто',
            'Пошук по ID, VIN-коду чи держ. номеру': 'Пошук по ID, VIN-коду чи держ. номеру',
        },
        ru:{
            'Пошук за параметрами авто': 'Поиск по параметрам авто',
            'Пошук по ID, VIN-коду чи держ. номеру': 'Поиск по ID, VIN-коду или гос. номеру',
        }
    }
}
