export default {
    messages: {
        uk: {
            'Від': 'Від',
            'До': 'До',
            'Рік від': 'Рік від',
            'Piк до': 'Piк до'
        },
        ru: {
            'Від': 'От',
            'До': 'До',
            'Рік від': 'Год от',
            'Piк до': 'Год до'
        },
    }
}
