export default {
    name: 'AutocompleteItem',
    props: {
        itemData: {
            type: Object,
            required: true,
        },
        popular: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isPopularItem() {
            return this.popular || this.itemData.count > 100
        }
    }
}
