import field from "../mixins/field";
import translate from "./i18n";

export default {
    name: 'FormSelect',
    mixins: [field],
    components: {
        'field-prompt-wrapper': require('../FieldPromptWrapper/index.vue').default
    },
    computed: {
        selectedValue() {
            return this.fieldData?.value || this.fieldData?.defaultValue;
        },
        options() {
            return [...this.fieldData.options, ...this.fieldData.defaultOptions]
        }
    },
    methods: {
        handleChange(event) {
            const { name, value } = event.target;
            this.fieldChange({ name, value })
        },
    },
    i18n: {
        ...translate
    }
}
