import translate from "./i18n";
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'FormPriceAverage',
  props: {
    chartData: {
      type: Array,
    },
  },
  components: {
    'form-tabs': require('./components/FormTabs/index.vue').default,
    'form-fields': require('./components/FormFields/index.vue').default,
    'form-toggle-tabs': require('./components/FormToggleTabs/index.vue').default
  },
  computed: {
    ...mapGetters('landing/averagePrice/search', {
      tabs: 'getTabsData',
      currentTab: 'getCurrentTab',
      currentTabFields: 'getCurrentTabFields',
    })
  },
  methods: {
    ...mapActions('landing/averagePrice/search', {
      changeSearchTab: 'changeSearchTab',
      submitAveragePriceForm: 'submitForm'
    }),
  },
  i18n: {
    ...translate
  }
};
