export default {
    messages: {
        uk: {
            'Схожі авто': 'Схожі авто',
        },
        ru: {
            'Схожі авто': 'Похожие авто',
        }
    }
}
