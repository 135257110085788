export default {
    name: 'AverageSelectItem',
    props: {
        id:{
            type: [String, Number],
            required: true,
        },
        data: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            return: true
        },
        isChecked: {
            type: Boolean,
            default: false
        }
    },
}
