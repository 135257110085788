export default {
	name: 'SuggestListBySearch',
	props: {
		'targetField': {
			type: String,
			default: 'id'
		},
		'isOptionsHidden': {
			type: Boolean,
			default: false,
		},
		'optionsSearched': {
			type: Array,
			default: () => [],
		},
		'hoveredIndex': {
			type: Number,
			default: -1,
		},
		'selected':{
			type: [String, Number],
			default: []
		},
	},
	components: {
		'autocomplete-item': require('./../Item/index.vue').default,
	},
	methods: {
		select(option) {
			this.$emit('set', option)
		},
	},
	updated() {
		if (!this.isOptionsHidden) {
			let item = this.$el.querySelector('.active');
			if (item) {
				this.$el.scrollTop = item.offsetTop - 50;
			}
		}
	}
};
