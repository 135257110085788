import {mapActions, mapGetters} from 'vuex'

import translate from "./i18n";

export default {
    name: 'FormFields',
    components: {
        'form-select': require('./Select/index.vue').default,
        'form-input': require('./Input/index.vue').default,
        'form-double-input': require('./DoubleInput/index.vue').default,
        'form-double-select': require('./DoubleSelect/index.vue').default,
        'form-autocomplete': require('./AverageAutocomplete/index.vue').default,
        'form-average-select': require('./AverageSelect/index.vue').default
    },
    props: {
        fields: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('landing/averagePrice', {
            currentTab: 'search/getCurrentTab',
            currentTabFields: 'search/getCurrentTabFields',
            loggerDataDefault: 'search/getLoggerDataDefault',
        }),
        fieldsArray() {
            return Object.values(this.fields);
        },
        visibleClearButton(){
            return Boolean(this.currentTabFields['categoryId']?.value || this.currentTabFields['omniId']?.value)
        },
    },
    methods: {
        ...mapActions('landing/averagePrice/search',{
            updateField: 'updateFieldData',
            updateLoggerData: 'updateAVGPriceLoggerData',
            resetField: 'resetField',
        }),

        clearFields() {
            try {
                Object.keys(this.currentTabFields).forEach(field => this.resetField(field))
            } catch (e) {
                console.log('ERROR clear fields');
            }
        },

        handlerChange(payload) {
            this.updateField(payload)
        },
        loggerAVGPriceField() {
            try {
                this.updateLoggerData({ click_action: this.loggerDataDefault.mapSubmitByTabs[this.currentTab], action_block: 2 });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceField===', e.toString());
            }
        },
        componentIsExist(name) {
            const componentsArr = Object.keys(this.$options.components);
            return componentsArr.includes(name)
        },
        fieldIsHide(field) {
            return Boolean(field?.fieldIsHide)
        }
    },
    i18n: {
        ...translate
    },
}
