import translate from "./i18n";

export default {
    name: 'PriceStatistics',
    props: {
        statistics: {
            type: Array,
            required: true
        }
    },
    i18n: {
        ...translate
    }
}
