export default {
    faq: [
        {
            question: 'Як розрахувати ринкову вартість автомобіля онлайн?',
            answer: 'У калькуляторі оцінки авто заповніть параметри',
        },
        {
            question: 'Як підраховується середня вартість автомобіля?',
            answer: 'Калькулятор вартості розраховує середньоринкову ціну',
        },
        {
            question: 'Оцінка автомобіля для продажу і де її зробити?',
            answer: 'Ви можете спробувати наш онлайн калькулятор',
        },
    ],
}
