export default {
    messages: {
        uk: {
            'Пошук': 'Пошук',
        },
        ru: {
            'Пошук': 'Поиск',
        }
    }
}
