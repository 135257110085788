export default {
	messages: {
		uk: {
			'Часто задавані питання': 'Часто задавані питання',

			'Як оцінити вживане авто для продажу?': 'Як оцінити вживане авто для продажу?',
			'Хочете швидко і безкоштовно визначити': 'Хочете швидко і безкоштовно визначити, оцінити і розрахувати ринкову вартість вживаного авто для продажу або покупки? За допомогою калькулятора вартості автомобіля онлайн ви зробите це без зайвих проблем і витрат на оцінювача машин',

			'Як розрахувати ринкову вартість автомобіля онлайн?': 'Як розрахувати ринкову вартість автомобіля онлайн?',
			'У калькуляторі оцінки авто заповніть параметри': 'У калькуляторі оцінки авто заповніть параметри, такі як тип транспорту, тип кузова, марку і модель і ви дізнаєтеся середню ціну вашого авто на ринку України. Не забувайте вказувати пробіг, рік, тип кпп і палива - вони також відіграють значну роль в незалежній оцінці вартості автомобіля та інших транспортних засобів.',

			'Як підраховується середня вартість автомобіля?': 'Як підраховується середня вартість автомобіля?',
			'Калькулятор вартості розраховує середньоринкову ціну': 'Калькулятор вартості розраховує середньоринкову ціну на підставі ціни, зазначеної в оголошеннях конкретних автомобілів на AUTO.RIA як по всій Україні, так і конкретного регіону. Результат оцінки є максимально точним, але може відрізнятися в залежності від комплектації.',

			'Оцінка автомобіля для продажу і де її зробити?': 'Оцінка автомобіля для продажу і де її зробити?',
			'Ви можете спробувати наш онлайн калькулятор': 'Ви можете спробувати наш онлайн калькулятор вище, або скористатися послугами оцінювача автомобілів. Різниця полягає в тому, що в першому випадку ви дізнаєтеся вартість автотранспортного засобу в залежності від його характеристик в цілому по ринку, а оцінювачі автомобілів видають свій висновок по конкретному автомобілю.',
		},
		ru: {
			'Часто задавані питання': 'Часто задаваемые вопросы',

			'Як оцінити вживане авто для продажу?': 'Как оценить подержанное авто для продажи?',
			'Хочете швидко і безкоштовно визначити': 'Хотите быстро и бесплатно определить, оценить и рассчитать рыночную стоимость подержанного авто для продажи или покупки? С помощью калькулятора стоимости автомобиля онлайн вы сделаете это без лишних проблем и затрат на оценщика машин',

			'Як розрахувати ринкову вартість автомобіля онлайн?': 'Как рассчитать рыночную стоимость автомобиля онлайн?',
			'У калькуляторі оцінки авто заповніть параметри': 'В калькуляторе оценки авто заполните параметры, такие как тип транспорта, тип кузова, марку и модель, и вы узнаете среднюю цену вашего авто на рынке Украины. Не забывайте указывать пробег, год, тип кпп и топлива – они также играют значительную роль в независимой оценке стоимости автомобиля и других транспортных средств.',

			'Як підраховується середня вартість автомобіля?': 'Как подсчитывается средняя цена автомобиля?',
			'Калькулятор вартості розраховує середньоринкову ціну': 'Калькулятор стоимости рассчитывает среднерыночную цену на основании цены, указанной в объявлениях конкретных автомобилей AUTO.RIA как по всей Украине, так и конкретного региона. Результат оценки максимально точен, но может отличаться в зависимости от комплектации.',

			'Оцінка автомобіля для продажу і де її зробити?': 'Оценка автомобиля для продажи и где его сделать?',
			'Ви можете спробувати наш онлайн калькулятор': 'Вы можете попробовать наш онлайн калькулятор выше, или воспользоваться услугами оценщика автомобилей. Разница состоит в том, что в первом случае вы узнаете стоимость автотранспортного средства в зависимости от его характеристик в целом по рынку, а оценщики автомобилей выдают свой вывод по конкретному автомобилю.',
		},
	}
};
