export default {
    messages: {
        uk: {
            'Спочатку оберіть': 'Спочатку оберіть',
            brandId: 'марку',
            categoryId: 'тип транспорту',
            'Тип транспорту': 'Тип транспорту',
            'Марка': 'Марка',
            'Модель': 'Модель',
            'Усі регіони': 'Усі регіони',
            'Усі типи палива': 'Усі типи палива',
            'Усі типи КПП': 'Усі типи КПП',
            'Усі типи привода': 'Усі типи привода',
            'Усі кольори': 'Усі кольори',
        },
        ru: {
            'Спочатку оберіть': 'Сначала выберите',
            brandId: 'марку',
            categoryId: 'тип транспорта',
            'Тип транспорту': 'Тип транспорта',
            'Марка': 'Марка',
            'Модель': 'Модель',
            'Усі регіони': 'Все регионы',
            'Усі типи палива': 'Все типы топлива',
            'Усі типи КПП': 'Все типы КПП',
            'Усі типи привода': 'Все типы привода',
            'Усі кольори': 'Все цвета',
        }
    }
}
