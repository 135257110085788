import { mapGetters } from 'vuex';
import translate from "./i18n";

export default {
  name: 'SimilarCars',
  components: {
    'similar-cars-item': require('./Item/index.vue').default
  },
  computed: {
    ...mapGetters('landing/averagePrice', {
      similarCars: 'getSimilarCars'
    })
  },
  i18n: {
    ...translate
  },
};
