export default {
    messages: {
        uk: {
            'Розрахувати ціну': 'Розрахувати ціну',
            'Очистити все': 'Очистити все',
        },
        ru: {
            'Розрахувати ціну': 'Рассчитать цену',
            'Очистити все': 'Очистить все',
        }
    }
}
