export default {
    messages: {
        uk: {
            'Змінити': 'Змінити',
            'Видалити': 'Видалити',
        },
        ru: {
            'Змінити': 'Изменить',
            'Видалити': 'Удалить',}
    }
}
