import translate from "./i18n";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'FormTabs',
    props: {
        currentTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        }
    },
    computed:{
        ...mapGetters('landing/averagePrice', {
            loggerDataDefault: 'search/getLoggerDataDefault',
        }),
    },
    methods:{
        ...mapActions('landing/averagePrice/search', {
            updateLoggerData: 'updateAVGPriceLoggerData',
        }),
        loggerAVGPriceTab(tabType) {
            try {
                this.updateLoggerData({ click_action: this.loggerDataDefault.mapTabs[tabType], action_block: 2 });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceTab===', e.toString());
            }
        }
    },
    i18n: {
        ...translate
    }
}
