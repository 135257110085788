import faqItems from "./faq";
import translate from "./i18n";
import {mapActions} from "vuex";

export default {
  name: 'PriceAverageFaq',
  data() {
    return {
      faq: faqItems.faq,
    };
  },
  metaInfo() {
    return {
      script: [
        {
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'FAQPage',
            mainEntity: faqItems.faq.map(({ question, answer }) => ({
              "@type": "Question",
              name: this.$t(question),
              acceptedAnswer: {
                '@type': 'Answer',
                text: this.$t(answer),
              }
            })),
          }), type: "application/ld+json"
        },
      ]
    }
  },
  methods: {
    ...mapActions('landing/averagePrice/search', {
      updateLoggerData: 'updateAVGPriceLoggerData',
    }),
    loggerAVGPriceFAQ(position) {
      try {
        this.updateLoggerData({ click_action: position, action_block: 6 });
      } catch (e) {
        console.error('===ERROR in loggerAVGPriceFAQ===', e.toString());
      }
    }
  },
  i18n: {
    ...translate
  },
};
