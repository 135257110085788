export default {
    messages: {
        uk: {
            'Динаміка росту та зниження цін': 'Динаміка росту та зниження цін на авто',
            'щотижневий моніторинг цін': 'щотижневий моніторинг цін',
            'Назад': 'Назад',
        },
        ru: {
            'Динаміка росту та зниження цін': 'Динамика роста и снижение цен на авто',
            'щотижневий моніторинг цін': 'еженедельный мониторинг цен',
            'Назад': 'Обратно',
        }
    }
}
