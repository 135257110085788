export default {
    name: 'NoticesAveragePrice',
    props: {
        notices: {
            type: Array,
            required: true
        }
    },
    components: {
        'notice-average-price': require('./Item/index.vue').default
    }
}
