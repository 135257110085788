export default {
    messages: {
        uk: {
            'Від': 'Від',
            'До': 'До',
            'Пробіг від, тис. км': 'Пробіг від, тис. км',
            'Пробіг до, тис. км': 'Пробіг до, тис. км',
            'Обʼєм двигуна від, л': 'Обʼєм двигуна від, л',
            'Обʼєм двигуна до, л': 'Обʼєм двигуна до, л',
        },
        ru: {
            'Від': 'От',
            'До': 'До',
            'Пробіг від, тис. км': 'Пробег от, тыс. км',
            'Пробіг до, тис. км': 'Пробег до, тыс. км',
            'Обʼєм двигуна від, л': 'Объём двигателя от, л',
            'Обʼєм двигуна до, л': 'Объём двигателя до, л',
        },
    }
}
