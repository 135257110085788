import { formingLabelsValuesSearchFields, formingLabelsValuePrice, formingLabelsValuePeriod } from '../../helpers/analyticsPriceAVG'
import translate from "./i18n";
import { mapGetters, mapActions } from 'vuex';
import {objectToQuery} from "./helpers/objectToQuery";

export default {
  name: 'PriceAverage',
  data() {
    return {
        logDataOld: {},
        evaluationLogData: {
            action_block: 4,
        }
    }
  },
  components: {
    'form-average-price': require('./Form/index.vue').default,
    'notices': () => import('./Notice/index.vue'),
    'graph': () => import('./Graph/index.vue'),
    'similar-cars': () => import('./SimilarCars/index.vue'),
    'faq': require('./Faq/index.vue').default,
    'verified-by-people': require('../../components/Common/VerifiedByPeople/index.vue').default,
    'evaluation': () => import('../../components/Cabinet/components/Evaluation/index.vue'),
    'alert': () => import('./Alert/index.vue'),
    'banner': () => import('./Banner/index.vue'),
  },
  computed:{
    ...mapGetters('landing/averagePrice', {
      chartData: 'getChartPriceData',
      getLoader: 'getLoader',
      noticeData: 'getAveragePriceNoticeData',
      omniIdData: 'search/getOmniIdData',
      currentTab: 'search/getCurrentTab',
      chipsData: 'getChipsData',
      currentTabFields: 'search/getCurrentTabFields',
      averagePriceStatistics: 'getAveragePriceStatistics',
      metaData: 'getMetaData',
      periodFieldData: 'search/getPeriodFieldData',
      loggerData: 'search/getLoggerData',
      loggerDataDefault: 'search/getLoggerDataDefault',
      formData: 'search/getFormData',
      searchType: 'getSearchType',
    }),
    link() {
      return this?.chipsData?.link;
    },
    // Чисто для SMM - використовується для позіціонування блоків
    hasQueryAudience() {
      return Boolean(this.$route?.query?.audience)
    }
  },
  methods: {
    ...mapActions('landing/averagePrice/search', {
      submitAveragePriceForm: 'submitForm',
      setFormByQuery: 'setFormByQuery',
      changeSearchTab: 'changeSearchTab',
    }),
    submitSearchForm() {
      if(this.$refs.formAveragePrice.$el.reportValidity()) {
        this.submitAveragePriceForm();
      }
      this.$refs.formAveragePrice.$el.reportValidity()
    },
    loggerAVGPrice(logData) {
      try {
        const logDataNew = {
          ...this.loggerDataDefault.eventData,
          ...logData,
          ...formingLabelsValuesSearchFields(this.currentTabFields),
          ...formingLabelsValuePrice(this.averagePriceStatistics),
          ...formingLabelsValuePeriod(this.periodFieldData),
          entry_point: this.loggerDataDefault.mapTabs[this.currentTab] || this.loggerDataDefault.mapSearchType[this.searchType],
          recommendation_version: this.metaData.versionAIML,
          search_text: this.omniIdData
        };

        if (JSON.stringify(logDataNew) !== JSON.stringify(this.logDataOld)) {
          this._slonik(logDataNew);
          this.logDataOld = logDataNew;
        }
      } catch (e) {
        console.error('===ERROR in loggerAVGPrice===', e.toString());
      }
    },
    getUniqParams() {
      const uniqParams = ['audience'];
      const routerUniqParams = Object.entries(this.$route?.query).filter(([key, value]) => uniqParams.includes(key));

      return routerUniqParams.length ? Object.fromEntries(routerUniqParams) : {}
    },
    setQuery() {
      const uniqParams = this.getUniqParams()
      const formQuery = objectToQuery(this.formData);
      this.$router.replace({
        path: this.$route.path,
        query: { currentTab: this.currentTab, ...formQuery, ...uniqParams},
      })
    }
  },
  watch: {
    loggerData(logData) {
      this.loggerAVGPrice(logData);
    },
    averagePriceStatistics() {
      this.loggerAVGPrice({ request_type: 2 });
    },
    formData: function (nevValue) {
      this.setQuery()
    },
    currentTab: {
      handler: function (newValue) {
        this.setQuery()
      },
    }
  },
  async mounted() {
    this.loggerAVGPrice({ request_type: 1 });
    const hasQuery = Object.keys(this.$route.query).length;

    if(hasQuery) {
      await this.setFormByQuery(this.$route.query);
       await this.submitAveragePriceForm();
    }
  },
  async serverPrefetch() {
    if(this.$route?.query?.currentTab) await this.changeSearchTab(this.$route?.query.currentTab)
  },
  metaInfo() {
    return {
      title: `AUTO.RIA™ — ${this.$t('Калькулятор розрахунку вартості авто онлайн')}`,
      meta: [
        { name: "description", content: this.$t('Онлайн розрахунок вартості автомобіля - швидкий спосіб оцінити середню ринкову вартість автомобіля з пробігом перед продажем') },
        { name: "keywords", content: "" },
        { property: "og:site_name", content: "AUTO.RIA" },
        { property: "og:title", content: "" },
        { name: "telegram:channel", content: "@auto_ria_news" },
        { property: "catalog_name", content: "priceAverageCalculator" },
        { property: "og:url", content: `https://auto.ria.com${this.langPrefix || ''}/price/average/?utm_source=facebook.com&utm_medium=addthis&utm_campaign=share` },
        { property: "og:description", content: "" },
        {name: 'ROBOTS', content: Object.keys(this.$route.query || {}).length ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW'},
      ],
      link: [
        {rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/price/average/'},
        {rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/price/average/'},
        {rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/price/average/'},
        {rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/price/average/'},
        {rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/price/average/'},
        {rel: 'canonical', href: `https://auto.ria.com${this.langPrefix || ''}/price/average/`}
      ]
    };
  },
  i18n: {
    ...translate
  }
};
