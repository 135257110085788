export default {
    messages: {
        uk: {
            'Введіть данні': 'Введіть ID, VIN-код чи держ. номер'
        },
        ru: {
            'Введіть данні': 'Введите ID, VIN-код или гос. номер'
        }
    }
}
