import translate from "./i18n";
import {mapActions} from "vuex";

export default {
    name: 'SimilarCarsItem',
    props: {
        car: {
            type: Object,
            required: true
        },
        position: {
            type: Number
        }
    },
    data() {
        return {
            imageLoaded: false
        }
    },
    computed: {
        carTitle() {
            return `${this.car.title} ${this.car.year}`
        },
        USDPrice() {
            if(!this.car?.price?.all?.USD) return ''
            const { value, currency } = this.car?.price?.all?.USD;
            return `${value} ${currency.sign}`
        },
        UAHPrice() {
            if(!this.car?.price?.all?.UAH) return ''
            const { value, currency } = this.car.price.all.UAH;
            return `${value} ${currency.abbreviated}`
        }
    },
    methods: {
        ...mapActions('landing/averagePrice/search', {
            updateLoggerData: 'updateAVGPriceLoggerData',
        }),
        loggerAVGPriceSimilarCars() {
            try {
                this.updateLoggerData({ click_action: this.position, action_block: 5, proposal_id: this.car.id });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceSimilarCars===', e.toString());
            }
        }
    },
    i18n: {
        ...translate
    }
}
