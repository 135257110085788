export default {
    messages: {
        uk: {
            'Калькулятор розрахунку вартості автомобіля': 'Калькулятор розрахунку вартості автомобіля',
            'Калькулятор розрахунку вартості авто онлайн': 'Калькулятор розрахунку вартості авто онлайн',
            'Онлайн розрахунок вартості автомобіля - швидкий спосіб оцінити середню ринкову вартість автомобіля з пробігом перед продажем': 'Онлайн розрахунок вартості автомобіля - швидкий спосіб оцінити середню ринкову вартість автомобіля з пробігом перед продажем',
            'Розрахунок середньої вартості авто': 'Розрахунок середньої вартості авто',
            'Знайдено': 'Знайдено',
            'Переглянути пропозицію': 'Переглянути пропозицію',
            'Не знайдено авто за держ.номером': 'Не знайдено авто за держ.номером',
            'Скористайтесь пошуком за параметрами авто.': 'Скористайтесь пошуком за параметрами авто.',
            'держ.номером': 'держ.номером {value}',
            'VIN-номером': 'VIN-номером {value}',
            'ID оголошення': 'ID оголошенням {value}',
            'Авто зі вказаними параметрами відсутні.' : 'Авто зі вказаними параметрами відсутні. Перевірте правильність даних',
            'За ХХХХХ інформація відсутня.': 'За {omniId} інформація відсутня. Перевірте правильність даних або введіть параметри авто у формі вручну.'
        },
        ru: {
            'Калькулятор розрахунку вартості автомобіля': 'Калькулятор расчета стоимости автомобиля',
            'Калькулятор розрахунку вартості авто онлайн': 'Калькулятор расчета стоимости авто онлайн',
            'Онлайн розрахунок вартості автомобіля - швидкий спосіб оцінити середню ринкову вартість автомобіля з пробігом перед продажем': 'Онлайн расчёт стоимости автомобиля - быстрый способ оценить среднюю рыночную стоимость автомобиля с пробегом перед продажей',
            'Розрахунок середньої вартості авто': 'Расчет средней стоимости авто',
            'Знайдено': 'Найдено',
            'Переглянути пропозицію': 'Просмотреть предложение',
            'Не знайдено авто за держ.номером': 'Не найдено авто по гос.номеру',
            'Скористайтесь пошуком за параметрами авто.': 'Воспользуйтесь поиском по параметрам авто.',
            'держ.номером': 'гос.номеру {value}',
            'VIN-номером': 'VIN-номеру {value}',
            'ID оголошення': 'ID объявлению {value}',
            'Авто зі вказаними параметрами відсутні.' : 'Авто с указанными параметрами отсутствуют. Проверьте правильность данных',
            'За ХХХХХ інформація відсутня.': 'По {omniId} информация отсутствует. Проверьте правильность данных или введите параметры авто в форме вручну.'
        }
    }
}
