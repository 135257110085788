const mapSearchFields = {
	bodyId: 'body_id',
	brandId: 'marka_id',
	categoryId: 'transport_type_id',
	colorId: 'color',
	driveId: 'drive_id',
	fuelId: 'fuel_id',
	gearBoxId: 'gearbox_id',
	modelId: 'model_id',
	stateId: 'state_id',
	engineVolume: {
		gte: 'engine_capacity_from',
		lte: 'engine_capacity_to',
	},
	mileage: {
		gte: 'ares_count_from',
		lte: 'ares_count_to',
	},
	year: {
		gte: 'year_from',
		lte: 'year_to',
	},
};

const mapPrices = {
	minPrice: 'avg_price_min',
	avgPrice: 'avg_price_avg',
	maxPrice: 'avg_price_max',
};

export function formingLabelsValuesSearchFields(fields) {
	const labels = {};
	try {
		Object.keys(fields).forEach((field) => {
			if (mapSearchFields[field] && fields[field]?.value !== null) {
				const { value = '' } = fields[field];
				if (typeof value === 'object') {
					Object.keys(value).forEach((valueDetails) => {
						if (mapSearchFields[field]?.[valueDetails]) labels[mapSearchFields[field][valueDetails]] = value[valueDetails];
					});
				} else {
					if (mapSearchFields[field]) labels[mapSearchFields[field]] = value;
				}
			}
		});
	} catch (e) {
		console.error('===ERROR in formingLabelsValuesSearchFields===', e.toString());
	}
	return labels;
}

export function formingLabelsValuePrice(prices) {
    const labels = {};
    try {
		prices.forEach(({ type = '', price: { USD = 0 } = {} }) => {
			if (mapPrices[type]) labels[mapPrices[type]] = USD;
        });
    } catch (e) {
        console.error('===ERROR in formingLabelsValuePrice===', e.toString());
    }
    return labels;
}

export function formingLabelsValuePeriod(period) {
    const labels = {};
    try {
        const { defaultValue, value } = period;
        labels.period = value || defaultValue;
    } catch (e) {
        console.error('===ERROR in formingLabelsValuePeriod===', e.toString());
    }
    return labels;
}
