import { mapGetters, mapActions } from 'vuex'

import translate from "./i18n";
import getDoubleNumber from "../../../helpers/getDoubleNumber";

const DAYS_PER_MOUTH = 30;

export default {
  name: 'GraphPriceAverage',
  components: {
    'period-select': require('../Form/components/FormFields/Select/index.vue').default,
    'chips': require('./Chips/index.vue').default,
    'average-price-chart' : require('./Chart/index.js').default,
    'price-statistics': require('./PriceStatistics/index.vue').default,
  },
  data() {
    return {
      dayPerWeek: 7,
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
      },
      chartPage: 1,
    }
  },
  computed: {
    ...mapGetters('landing/averagePrice', {
      currentTab: 'search/getCurrentTab',
      periodFieldData: 'search/getPeriodFieldData',
      prices: 'getChartPriceData',
      chipsData: 'getChipsData',
      searchUrl: 'getSearchUrl',
      priceStatistics: 'getAveragePriceStatistics',
      responseType: 'getResponseType',
    }),
    periodRange(){
      return this.periodFieldData?.value || this.periodFieldData.defaultValue;
    },
    chartData(){
      const minIndex = (this.chartPage - 1) * this.dayPerWeek;
      const maxIndex =  (this.chartPage * this.dayPerWeek) - 1;

      return this.prices.reduce((acc, el, index) => {
        const dateLabel = this.getDateLabel(el.date);
        if(this.isMobile && Number(this.periodRange) === DAYS_PER_MOUTH) {
          if(minIndex <= index && index <= maxIndex) {
            acc.labels.push(dateLabel);
            acc.datasets[0].data.push(el.price.USD);
          }
        } else {
          acc.labels[index] = dateLabel;
          acc.datasets[0].data[index] = el.price.USD;
        }
        return acc;
      }, {
        labels: [],
        datasets: [{
          data: []
        }],
      })
    },
    lastPage(){
      return Math.round(this.prices.length / this.dayPerWeek);
    },
  },
  methods: {
    ...mapActions('landing/averagePrice/search',{
      changePeriod: 'changePeriod',
      updateFieldData: 'updateFieldData',
      updateLoggerData: 'updateAVGPriceLoggerData',
      changeSearchTab: 'changeSearchTab',
    }),
    scrollToCurrent() {
      if (this?.prices?.length) {
        const timeout = setTimeout(() => {
          this.$el?.scrollIntoView({behavior: 'smooth', block: 'start'});
          clearTimeout(timeout);
        }, 100);
      }
    },
    handlerPeriod(data){
      this.changePeriod(data);
      this.loggerAVGPriceGraph({ click_action: 3 })
    },
    deleteField(name) {
      if(this.currentTab !== 'byParams') {
        this.changeSearchTab('byParams')
      }
      this.updateFieldData({name, value: null});
      this.$emit('submitForm')
      this.loggerAVGPriceGraph({ current_point_action: name, click_action: 2 });
    },
    getDateLabel(date) {
      let resultDate;
      const currentDate = new Date(date);

      const day = getDoubleNumber(currentDate.getDate());
      const month = getDoubleNumber(currentDate.getMonth() + 1);
      const year =   getDoubleNumber(currentDate.getFullYear())

      if(Number(this.periodRange) === DAYS_PER_MOUTH) {
        resultDate = [day, month];
      } else {
        resultDate = [month, year];
      }
      return resultDate.join('.');
    },
    loggerAVGPriceGraph(logData) {
      try {
        this.updateLoggerData({ ...logData, action_block: 3 });
      } catch (e) {
        console.error('===ERROR in loggerAVGPriceGraph===', e.toString());
      }
    }
  },
  i18n: {
    ...translate
  },
};
