export default {
    messages: {
        uk: {
            'Мінімальна ціна': 'Мінімальна ціна',
            'Середня ціна': 'Середня ціна',
            'сформована на основі проданих': 'сформована на основі проданих',
            'Максимальна ціна': 'Максимальна ціна',
            '* AUTO.RIA не впливає на курс валют.': '* AUTO.RIA не впливає на курс валют. Ознайомитися з актуальними курсами валют можна на сторонніх ресурсах.',
        },
        ru: {
            'Мінімальна ціна': 'Минимальная цена',
            'Середня ціна': 'Середняя цена',
            'сформована на основі проданих': 'сформирована на основе проданных',
            'Максимальна ціна': 'Максимальная цена',
            '* AUTO.RIA не впливає на курс валют.': '* AUTO.RIA не влияет на курс валют. Ознакомиться с актуальными курсами валют можно на сторонних ресурсах.',
        }
    }
}
