export default {
    messages: {
        uk: {
            'Спочатку оберіть': 'Спочатку оберіть поле {fields} | Спочатку оберіть поле {fields} | Спочатку оберіть поля {fields}',
            'bodyId': 'тип кузову',
            'modelId': 'модель',
            'year': 'рік',
            'brandId': 'марка',
            'categoryId': 'тип транспорту',
            'generationId': 'покоління',
            // validation Messages
            'required': 'Спочатку оберіть поле {field}'
        },
        ru: {
            'Спочатку оберіть': 'Сначала выберите поле {fields} | Сначала выберите поле {fields} | Сначала выберите поля {fields}',
            'bodyId': 'тип кузова',
            'modelId': 'модель',
            'year': 'год',
            'brandId': 'марка',
            'categoryId': 'тип транспорта',
            'generationId': 'поколение',
            // validation Messages
            'required': 'Спочатку оберіть поле {field}'
        },
    }
}
