export default {
    messages: {
        uk: {
            'Знайдіть найвигіднішу ціну': 'Знайдіть найвигіднішу ціну серед усіх пропозицій на AUTO.RIA або додайте своє авто',
            'Знайти авто': 'Знайти авто',
            'Продати авто': '',
        },
        ru: {
            'Знайдіть найвигіднішу ціну': 'Найдите самую выгодную цену среди всех предложений на AUTO.RIA или добавьте свое авто',
            'Знайти авто': 'Найти авто',
            'Продати авто': 'Продать авто',
        }
    }
}
