export default {
    messages: {
        uk: {
            'Всі': 'Всі',
            'Усі покоління': 'Усі покоління',
            'Усі модифікації': 'Усі модифікації',
            'Усі типи кузова': 'Усі типи кузова',
        },
        ru: {
            'Всі': 'Все',
            'Усі покоління': 'Все поколения',
            'Усі модифікації': 'Все модификации',
            'Усі типи кузова': 'Все типы кузова',
        }
    }
}
