export default {
    messages: {
        uk: {
            'Перевірений VIN-код': 'Перевірений VIN-код',
        },
        ru: {
            'Перевірений VIN-код': 'Проверен VIN-код',
        }
    }
}
