import translate from "./i18n";
import {mapActions} from "vuex";

export default {
    name: 'Alert',
    props: {
        link: {
            type: Object,
            required: true,
        }
    },
    methods: {
        ...mapActions('landing/averagePrice/search', {
            updateLoggerData: 'updateAVGPriceLoggerData',
        }),
        loggerAVGPriceButton(button) {
            try {
                this.updateLoggerData({ click_action: button, action_block: 8 });
            } catch (e) {
                console.error('===ERROR in loggerAVGPriceButton===', e.toString());
            }
        }
    },
    data() {
        return {}
    },
    i18n: {
        ...translate
    }
}
