import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
    extends: Line,
    name: 'AveragePriceChart',
    props: ['options', 'chartData', 'prices'],
    mixins: [reactiveProp],
    data(){
        return {
            gradient: null
        }
    },
    methods: {
        getItemDataByIndex(index) {
            return this.prices[index]
        },
        mountChart(){
            const v = this
            this.gradient = this.$refs.canvas
                .getContext("2d")
                .createLinearGradient(0, 0, 0, 450);

            this.gradient.addColorStop(0, "rgba(165,239,255, 0.9)");
            this.gradient.addColorStop(0.1, "rgba(165,239,255, 0.9)");
            this.gradient.addColorStop(0.2, "rgba(165,239,255, 0.35)");
            this.gradient.addColorStop(0.4, "rgba(165,239,255, 0.075)");
            this.gradient.addColorStop(1, "rgba(165,239,255, 0)");

            const { datasets, labels } = this.chartData;
            const allPrices =  this.prices.map(el => el.price.USD);

            this.renderChart(
                {
                    labels,
                    datasets: [
                        {
                            ...datasets[0],
                            borderColor: '#256799',
                            backgroundColor: this.gradient,
                            pointBackgroundColor: 'white',
                            pointBorderColor: '#256799',
                            pointBorderWidth: 2,
                            pointRadius: 4,
                            pointHoverBackgroundColor: '#256799'
                        }
                    ]
                },
                {
                    interaction: { mode: 'index' },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                            offset: true
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false,
                            },
                            offset: true,
                            ticks: {
                                suggestedMin: Math.min.apply(null, allPrices),
                                suggestedMax: Math.max.apply(null, allPrices),
                                callback: function(value, index, ticks) {
                                    return value + '$';
                                }
                            },

                        }],
                    },
                    tooltips: {
                        enabled: false,
                    },
                    hover: {
                        onHover(event, chartElement) {
                            event.target.style.cursor = chartElement.length ? 'pointer' : 'default'
                            const tooltipContainer = document.getElementById('chartjs-tooltip-custom');

                            if(chartElement.length){
                                const itemChartData = chartElement[0]._index;

                                const itemPriceData = v.getItemDataByIndex(itemChartData)

                                const tooltipHTML = `
                            <div class="header">${itemPriceData.date}</div> 
                            <div class="content">
                                <span class="bold">${itemPriceData.price.USD.toLocaleString('ru')} $</span>
                                <span class="point">•</span>
                                ${itemPriceData.price.UAH.toLocaleString('ru')} грн
                            </div>
                        `

                                tooltipContainer.innerHTML = tooltipHTML;

                                tooltipContainer.style.opacity = 1;
                                tooltipContainer.style.left = (chartElement[0]._model.x + 5)+ 'px';
                                tooltipContainer.style.top = (chartElement[0]._model.y + 5)+ 'px';
                            }
                            else {
                                tooltipContainer.style.opacity = 0;
                            }
                        }
                    },
                    ...this.options,
                }
            );
            this.$emit('chartRendered');
        }
    },
    mounted() {
        this.mountChart();
    },
    watch:{
        'prices': {
            handler(newValue, oldValue) {
                this.$data._chart.destroy()
                this.mountChart();
            },
        }
    }
};
