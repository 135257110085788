import translate from "./i18n";
export default {
    name: 'Banner',
    data() {
        return {
            isLoaded: false,
        }
    },
    computed: {
        srcPng() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/168/${this.isMobile ? '16891/16891' : '16894/16894'}.png`;
        },
        srcAvif() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/168/${this.isMobile ? '16892/16892' : '16896/16896'}.avif`;
        },
        srcWebp() {
            return `https://cdn.riastatic.com/docs/pictures/common/1/168/${this.isMobile ? '16893/16893' : '16895/16895'}.webp`;
        },
        srcWidth() {
            return `${this.isMobile ? 120 : 240}`;
        },
        srcHeight() {
            return `${this.isMobile ? 120 : 166}`;
        }
    },
    methods: {
        onImgLoad () {
            this.isLoaded = true;
        }
    },
    i18n: {
        ...translate
    }
}
