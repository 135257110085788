import field from "../mixins/field";
import translate from "./i18n";

export default {
    name: 'FormDoubleInput',
    mixins: [field],
    methods: {
        getComparedValues(values) {
            const { gte, lte } = values;
            if(!gte || !lte) return values;
            if(Number(gte) > Number(lte)) {
                return {
                    'gte': lte,
                    'lte': gte
                }
            } else return values;
        },
        handleChange(event) {
            const { name, value } = event.target;
            const combinedValues = Object.assign({}, this.fieldData.value, { [name]: value });
            const comparedValues = this.getComparedValues(combinedValues)
            this.fieldChange({
                name: this.fieldData.fieldName,
                value: comparedValues
            })
        }
    },
    i18n: {
        ...translate
    },
}
