export default {
    messages: {
        uk: {
            'RIA.com ділиться найціннішим': 'RIA.com ділиться найціннішим',
            'Користуйтесь можливостями API': 'Користуйтесь можливостями API розрахунку середньої ціни від AUTO.RIA для власного бізнесу чи створення додатків',
            'На основі даних про мільйони авто': 'На основі даних про мільйони авто, що продавались останні 9 років - Підсилений штучним інтелектом, який прогнозує точну вартість у реальному час',
            'Детальніше': 'Детальніше',
        },
        ru: {
            'RIA.com ділиться найціннішим': 'RIA.com делится самым ценным',
            'Користуйтесь можливостями API': 'Пользуйтесь возможностями API расчета средней цены от AUTO.RIA для собственного бизнеса или создания приложений',
            'На основі даних про мільйони авто': 'На основе данных о миллионах авто, продававшихся последние 9 лет - усилен искусственным интеллектом, который прогнозирует точную стоимость в реальном времени.',
            'Детальніше': 'Подробнее',
        }
    }
}
