import {mapActions} from "vuex";

export default {
  name: 'NoticeAveragePrice',
  props: {
    variant: {
      type: String,
      default: 'success',
      validator(value) {
        return ['success', 'warning', 'error'].includes(value)
      }
    },
    text: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      default: ''
    },
    linkHref: {
      type: String,
      default: '#'
    },
  },
  computed: {
    noticeType() {
      const types = {
        'success': 'notice--green',
        'warning': 'notice--orange',
        'error': 'notice--red'
      }
      return types[this.variant]
    }
  },
  methods: {
    ...mapActions('landing/averagePrice/search', {
      updateLoggerData: 'updateAVGPriceLoggerData',
    }),
    loggerAVGPriceNotice() {
      try {
        this.updateLoggerData({ click_action: this.key + 1, action_block: 7 });
      } catch (e) {
        console.error('===ERROR in loggerAVGPriceNotice===', e.toString());
      }
    }
  },
};
